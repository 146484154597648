<template>
  <div class="menu-body" v-if="
    $oidc.isAuthenticated &&
    $oidc.userProfile &&
    $oidc.userProfile.roles &&
    $oidc.userProfile.roles.length > 0
  ">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="main-con">
      <div class="mat-info-con">
        <div class="plant-dd">
          <label for="pillar" class="form-label select-lable lable-text">Pillar</label>
          <select class="form-control select-text select-text-pd cust-st-icon" id="pillar" v-model="pillar"
            @change="onchangepillar(pillar)">
            <option selected>Select Pillar</option>
            <option v-for="eachPillar in pillars" :key="eachPillar.pillarId" :value="eachPillar.pillarId">
              {{ eachPillar.pillarName }}
            </option>
          </select>
        </div>
        <div class="plant-card">
          <div class="info-card-lable">Current Maturity Level</div>
          <div class="info-card-value">{{ currentMaturityLevel }}</div>
        </div>
        <div class="plant-card">
          <div class="info-card-lable">Current Maturity Score</div>
          <div class="info-card-value">{{ currentMaturityScore }}</div>
        </div>
        <div style="display: flex">
          <div class="plant-card top-card">
            <div class="info-card-lable">Target</div>
            <div class="info-card-value">{{ this.targetActions }}</div>
          </div>
          <div class="plant-card top-card">
            <div class="info-card-lable">Committed</div>
            <div class="info-card-value">{{ this.committedActions }}</div>
          </div>
          <div class="plant-card top-card">
            <div class="info-card-lable">Completed</div>
            <div class="info-card-value">{{ this.completedActions }}</div>
          </div>
        </div>
      </div>
      <div class="status-last-asmt-con">
        <div style="display: flex; align-items: center">
          <span style="margin-right: 9px">Status :</span>
          <img alt="more" class="status-icons" style="cursor: default" src="../../../assets/maturity-not-start.svg" />
          <span class="status-name"> Not Started </span>
          <img alt="more" class="status-icons" style="cursor: default" src="../../../assets/blue-tick.svg" />
          <span class="status-name"> On Track </span>
          <img alt="more" class="status-icons" style="cursor: default" src="../../../assets/off-track.svg" />
          <span class="status-name"> Off Track </span>
          <img alt="more" class="status-icons" style="cursor: default" src="../../../assets/maturity-cmpl.svg" />
          <span class="status-name"> Completed </span>
          <img alt="more" class="status-icons" style="cursor: default" src="../../../assets/yellow-tick.svg" />
          <span class="status-name"> Not Sustained </span>
          <img alt="more" class="status-icons" style="cursor: default" src="../../../assets/red-warning.svg" />
          <span class="status-name"> Major Changes </span>
          <img alt="more" class="status-icons" style="cursor: default" src="../../../assets/deleted.svg" />
          <span class="status-name"> Deleted </span>
        </div>
        <div class="last-asmt-con" v-if="lastAssessmentDate">
          <span>Last Updated :</span>
          <span class="last-asmt-date">
            {{ lastAssessmentDate | dateFormater }}
          </span>
        </div>
      </div>
      <div class="view-asmt view-result-page">
        <b-table id="view-asmt-table" class="action-table gn-table-sm" responsive :items="items" :fields="fields"
          sticky-header>
          <template #cell(pillar)="row">
            {{ row.item.index + " " + row.item.pillar }}
          </template>
          <template #cell(level0)="row">
            <div v-if="Array.isArray(row.item.level0)">
              <img alt="more" v-for="srcURL in row.item.level0" :key="srcURL" class="status-icons"
                @click="takeAssement(0, row.item.principleId)" :src="iconSelected(srcURL, row.item.index + 'L0')" />
            </div>
            <div v-else>
              <img alt="more" class="status-icons" @click="takeAssement(0, row.item.principleId)"
                :src="iconSelected(row.item.level0, row.item.index + 'L0')" />
            </div>
          </template>
          <template #cell(level1)="row">
            <div v-if="Array.isArray(row.item.level1)">
              <img alt="more" v-for="srcURL in row.item.level1" :key="srcURL" class="status-icons"
                @click="takeAssement(1, row.item.principleId)" :src="iconSelected(srcURL, row.item.index + 'L1')" />
            </div>
            <div v-else>
              <img alt="more" class="status-icons" @click="takeAssement(1, row.item.principleId)"
                :src="iconSelected(row.item.level1, row.item.index + 'l1')" />
            </div>
          </template>
          <template #cell(level2)="row">
            <div v-if="Array.isArray(row.item.level2)">
              <img alt="more" v-for="srcURL in row.item.level2" :key="srcURL" class="status-icons"
                @click="takeAssement(2, row.item.principleId)" :src="iconSelected(srcURL, row.item.index + 'L2')" />
            </div>
            <div v-else>
              <img alt="more" class="status-icons" @click="takeAssement(2, row.item.principleId)"
                :src="iconSelected(row.item.level2, row.item.index + 'l2')" />
            </div>
          </template>
          <template #cell(level3)="row">
            <div v-if="Array.isArray(row.item.level3)">
              <img alt="more" v-for="srcURL in row.item.level3" :key="srcURL" class="status-icons"
                @click="takeAssement(3, row.item.principleId)" :src="iconSelected(srcURL, row.item.index + 'L3')" />
            </div>
            <div v-else>
              <img alt="more" class="status-icons" @click="takeAssement(3, row.item.principleId)"
                :src="iconSelected(row.item.level3, row.item.index + 'l3')" />
            </div>
          </template>
          <template #cell(level4)="row">
            <div v-if="Array.isArray(row.item.level4)">
              <img alt="more" v-for="srcURL in row.item.level4" :key="srcURL" class="status-icons"
                @click="takeAssement(4, row.item.principleId)" :src="iconSelected(srcURL, row.item.index + 'L4')" />
            </div>
            <div v-else>
              <img alt="more" class="status-icons" @click="takeAssement(4, row.item.principleId)"
                :src="iconSelected(row.item.level4, row.item.index + 'l4')" />
            </div>
          </template>
          <template #cell(level5)="row">
            <div v-if="Array.isArray(row.item.level5)">
              <img alt="more" v-for="srcURL in row.item.level5" :key="srcURL" class="status-icons"
                @click="takeAssement(5, row.item.principleId)" :src="iconSelected(srcURL, row.item.index + 'L5')" />
            </div>
            <div v-else>
              <img alt="more" class="status-icons" @click="takeAssement(5, row.item.principleId)"
                :src="iconSelected(row.item.level5, row.item.index + 'l5')" />
            </div>
          </template>
        </b-table>
        <div v-if="!items.length" class="no-data">
          <img alt="no data" src="../../../assets/No_Data.svg" />
          <span class="ed-date">No Records Found</span>
        </div>
      </div>
      <div class="back-btn-con button-reg">
        <b-button pill class="add-action-btn" @click="back">Back to Maturity Assessment</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";

import OnTrack from "../../../assets/blue-tick.svg";
import OffTrack from "../../../assets/off-track.svg";
import Completed from "../../../assets/maturity-cmpl.svg";
import NotStarted from "../../../assets/maturity-not-start.svg";
import NotSustained from "../../../assets/yellow-tick.svg";
import MajorChange from "../../../assets/red-warning.svg";
import Deleted from "../../../assets/deleted.svg";


export default {
  name: "ViewAssessment",
  data() {
    return {
      showLoader: true,
      plantId: "",
      plantName: "",
      pillarName: "",
      pillar: 1,
      pillars: [],
      principles: [],
      currentMaturityScore: "",
      currentMaturityLevel: "",
      lastAssessmentDate: "",
      levelId: "",
      targetActions: "",
      committedActions: "",
      completedActions: "",
      fields: [
        {
          key: "pillar",
          sortable: false,
          label: "",
        },
        {
          key: "level0",
          label: "Level 0",
          sortable: false,
        },
        {
          key: "level1",
          label: "Level 1",
          sortable: false,
        },
        {
          key: "level2",
          label: "Level 2",
          sortable: false,
        },
        {
          key: "level3",
          label: "Level 3",
          sortable: false,
        },
        {
          key: "level4",
          label: "Level 4",
          sortable: false,
        },
        {
          key: "level5",
          label: "Level 5",
          sortable: false,
        },
      ],
      items: [
        {
          principleId: "",
          index: "",
          pillar: " - ",
          level0: "false",
          level1: "false",
          level2: "false",
          level3: "false",
          level4: "false",
          level5: "false",
        },
        {
          principleId: "",
          index: "",
          pillar: " - ",
          level0: "false",
          level1: "false",
          level2: "false",
          level3: "false",
          level4: "false",
          level5: "false",
        },
        {
          principleId: "",
          index: "",
          pillar: " - ",
          level0: "false",
          level1: "false",
          level2: "false",
          level3: "false",
          level4: "false",
          level5: "false",
        },
        {
          principleId: "",
          index: "",
          pillar: " - ",
          level0: "false",
          level1: "false",
          level2: "false",
          level3: "false",
          level4: "false",
          level5: "false",
        },
        {
          principleId: "",
          index: "",
          pillar: " - ",
          level0: "false",
          level1: "false",
          level2: "false",
          level3: "false",
          level4: "false",
          level5: "false",
        },
        {
          principleId: "",
          index: "",
          pillar: " - ",
          level0: "false",
          level1: "false",
          level2: "false",
          level3: "false",
          level4: "false",
          level5: "false",
        },
      ],
    };
  },
  components: {
    Breadcrumb,
    Loader,
    OnTrack,
    OffTrack,
    Completed,
    NotStarted,
    NotSustained,
    MajorChange,
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 1,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Maturity Assessment Results - " + this.plantName,
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Maturity Model Assessment / ",
          redirect: "/assessment",
          primary: true,
        },
        { name: "View Assessment", primary: false },
      ],
    });
  },
  computed: {},
  created() {
    this.plantId = this.$route.params.plantId;
    this.plantName = this.$route.params.plantName;
    this.pillar = this.$route.params.pillarId;
    // //console.log(this.plantName);
    this.$store.dispatch("breadcrumb", {
      title: "Maturity Assessment Results - " + this.plantName,
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Maturity Model Assessment / ",
          redirect: "/assessment",
          primary: true,
        },
        { name: "View Assessment", primary: false },
      ],
    });

    this.getPillarListData();
    this.getPrincipleTableData();
    this.getCurrentMaturityScore();
  },
  methods: {
    getPlantPillarDetails() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(
        Api.PLANTPILLARDETAILS + this.plantId,
        "get"
      ).then((data) => {
        let pillarDetails = data.data;
        for (const element of pillarDetails) {
          if (parseInt(element.pillarId) === parseInt(this.pillar)) {
            this.targetActions = element.overallTargetActions;
            this.committedActions = element.totalActionCommited;
            this.completedActions = element.totalActionCompleted;
          }
        }
        this.showLoader = false;
      });
    },

    iconSelected(statusName, pos) {
      ////console.log(pos, statusName);
      statusName = statusName ? statusName.toUpperCase() : "";      
      switch (statusName) {
        case "NOT STARTED":
          return NotStarted;
        case "NOT SUSTAINED":
          return NotSustained;
        case "OFF TRACK":
          return OffTrack;
        case "ON TRACK":
          return OnTrack;
        case "COMPLETED":
          return Completed;
        case "MAJOR CHANGE":
          return MajorChange;
        case "DELETED":
          return Deleted
        default:
          return NotStarted;
      }
    },
    onchangepillar() {
      // //console.log(data);
      this.showLoader = true;
      ////console.log(event);
      ////console.log("pillarid ", this.pillar);
      this.getPrincipleTableData();
      this.getCurrentMaturityScore();
      this.getPillarName();
    },
    getPillarName() {
      this.pillars.forEach((i) => {
        if (i.pillarId == this.pillar) {
          this.pillarName = i.pillarName;
        }
      });
    },
    takeAssement(levelId, principleId) {   
      this.$router.push(
        "/take-assessment/" +
        this.plantId +
        "/" +
        this.pillar +
        "/" +
        this.pillarName +
        "/view-assessment" +
        "/" +
        this.plantName +
        "/" +
        levelId +
        "/" +
        principleId
      );
    },
    back() {
      this.$router.push("/assessment");
    },
    getPillarListData() {
      let url = Api.PILLARLIST + this.pillar;
      ////console.log(url);
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        // //console.log("PillarList ", data);
        this.pillars = [];
        data.data.forEach((i) => {
          if (i.active == "Y") this.pillars.push(i);
        });        
        this.getPillarName();
      });
    },
    getCurrentMaturityScore() {
      let url =
        Api.MATURITYSCORE +
        "?PillarId=" +
        this.pillar +
        "&PlantId=" +
        this.plantId;
      // //console.log(url);
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        this.currentMaturityScore = data.success
          ? parseFloat(data.data.currentMaturityScore).toFixed(2)
          : "NA";
        this.currentMaturityLevel = data.success
          ? data.data.currentMaturityLevel
          : "NA";
        // console.log("fffffff", data.data.lastAssessmentDate)

        this.lastAssessmentDate =
          data.success && data.data.lastAssessmentDate ? new Date() : "";
      });
    },
    getPrincipleTableData() {
      let url = Api.PRINCIPLEWISESTATUS + this.pillar + "/" + this.plantId;
      ////console.log(url);
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        console.log("PRINCIPLEWISESTATUS ", data);
        (this.showLoader = !data.success), (this.principles = data.data.result);
        // //console.log("PRINCIPLEWISESTATUS ", this.principles);
        if (data.success) {
          this.items = [];
          let arr = [];
          this.principles.forEach(function (element, I) {
            if (element.principleId > 0) {
              let level0 = element["level0"] ? element["level0"] : "";
              if (level0.match("(Major Change)")) {
                level0 = [
                  level0.substr(0, level0.match("(Major Change)").index - 2),
                  level0.match("(Major Change)")[0],
                ];
              }
              let level1 = element["level1"] ? element["level1"] : "";
              if (level1.match("(Major Change)")) {
                level1 = [
                  level1.substr(0, level1.match("(Major Change)").index - 2),
                  level1.match("(Major Change)")[0],
                ];
              }
              let level2 = element["level2"] ? element["level2"] : "";
              if (level2.match("(Major Change)")) {
                level2 = [
                  level2.substr(0, level2.match("(Major Change)").index - 2),
                  level2.match("(Major Change)")[0],
                ];
              }
              let level3 = element["level3"] ? element["level3"] : "";
              if (level3.match("(Major Change)")) {
                level3 = [
                  level3.substr(0, level3.match("(Major Change)").index - 2),
                  level3.match("(Major Change)")[0],
                ];
              }
              let level4 = element["level4"] ? element["level4"] : "";
              if (level4.match("(Major Change)")) {
                level4 = [
                  level4.substr(0, level4.match("(Major Change)").index - 2),
                  level4.match("(Major Change)")[0],
                ];
              }
              let level5 = element["level5"] ? element["level5"] : "";
              if (level5.match("(Major Change)")) {
                level5 = [
                  level5.substr(0, level5.match("(Major Change)").index - 2),
                  level5.match("(Major Change)")[0],
                ];
              }

              let obj = {
                principleId: element.principleId,
                index: element.principleNumber,
                pillar: element.principleName,
                level0: level0,
                level1: level1,
                level2: level2,
                level3: level3,
                level4: level4,
                level5: level5,
              };
              arr.push(obj);
            }
          });
          this.items = arr;
          this.getPlantPillarDetails();
          // console.log("**********",arr)
        } else {
          this.items = [];
        }
      });
    },
  },
};
</script>
<style scoped>
.main-con {
  padding-bottom: 1em;
}

.last-asmt-con {
  font-size: 1.083rem;
  font-family: "BarlowR", sans-serif;
}

.status-last-asmt-con {
  font-size: 1.083rem;
  font-family: "BarlowR", sans-serif;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.mat-info-con {
  padding: 2rem;
  display: flex;
}

.plant-dd {
  width: 20%;
}

.plant-select {
  height: 54px;
  border-color: #313131;
}

.plant-card {
  background: #fffce3;
  width: 160px;
  height: 54px;
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 5px;
  border-radius: 7px;
}

.add-action-btn {
  margin: 1.8rem 0;
  background: #274ba7;
  border-color: #274ba7;
  width: 20rem;
  padding: 1rem;
}

.add-action-btn:hover {
  border-color: #274ba7 !important;
  color: #fff !important;
  background: #274ba7 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active {
  border-color: #274ba7 !important;
  color: #fff !important;
  background: #274ba7 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.back-btn-con {
  text-align: right;
  padding-right: 3em;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

#view-asmt-table tbody tr {
  height: 4rem;
  background-color: #274ba7;
}

.last-asmt-date {
  font-family: "BarlowM", sans-serif;
}

.status-icons {
  margin: 0px 5px;
  width: 1.66rem;
  height: 1.66rem;
  cursor: pointer;
}

.status-name {
  margin-right: 1rem;
  font: 1.08rem "BarlowM", sans-serif;
  color: #313131;
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .plant-card[data-v-dcabda2e] {
    width: 100% !important;
    margin-left: 0em !important;
    margin-top: 1em;
  }
}

@media only screen and (min-width: 1800px) {
  .status-icons {
    width: 3rem;
    height: 3rem;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1799px) {
  .status-icons {
    width: 2rem;
    height: 2rem;
  }
}
</style>
